import { BaseApiModel } from "@/model/common/BaseApiModel";
import { CompanyTypeEnum } from "../enums/CompanyTypeEnum";

export class Company extends BaseApiModel {
    type: CompanyTypeEnum;
    name: string;
    business_name: string;
    business_address: string;
    business_city: string;
    business_zip: string;
    business_country: string;
    phone: string;
    email: string;
    note: string;
    trucks_num: number;
}