import { Company } from "@/model/api/Company";
import { CompanyTypeEnum } from "@/model/enums/CompanyTypeEnum";
import { companiesService } from "@services/companies.service";
import { Options, Prop, Vue } from "vue-property-decorator";
import { CompaniesRoutesEnum } from "../router";


@Options({
})
export default class DetailPage extends Vue {
  @Prop() readonly companyId!: string;

  company: Company = null;

  get isNew() {
    return this.companyId === 'new';
  }

  get backRoute() {
    return { name: this.$route.name == CompaniesRoutesEnum.CUSTOMER_DETAIL ? CompaniesRoutesEnum.CUSTOMERS_LIST : CompaniesRoutesEnum.SUPPLIERS_LIST }
  }

  get title() {
    const prefix = this.isNew ? "New" : "Edit";
    return this.$route.name == CompaniesRoutesEnum.CUSTOMER_DETAIL ? `${prefix} Customer` : `${prefix} Supplier`;
  }

  get companyType() {
    return this.$route.name == CompaniesRoutesEnum.CUSTOMER_DETAIL ? CompanyTypeEnum.CUSTOMER : CompanyTypeEnum.SUPPLIER;
  }

  get isSupplier() {
    return this.companyType == CompanyTypeEnum.SUPPLIER;
  }

  onSave() {
    this.$waitFor(
      async () => {
        if (this.isNew) {
          const response = await companiesService.create(this.company);
        } else {
          const response = await companiesService.updatePatch(this.company);
        }
        this.$router.replace(this.backRoute).then(() =>
          this.$successMessage("Success")
        );
      },
      this.$t('Operation failed')
    );
  }

  onDelete() {
    this.$confirmMessage("Are you sure?")
      .then(r => {
        if (r) {
          this.deleteCompany();
        }
      })
  }

  private deleteCompany() {
    this.$waitFor(
      async () => {
        await companiesService.deleteBySystem(this.company);

        this.$router.replace(this.backRoute)
          .then(() =>
            this.$successMessage("Company successfully deleted")
          );
      },

      "Deleting Company failed"
    )
  }


  private _loadCompany() {
    return this.$waitFor(
      async () => {
        const cmp = await companiesService.getById(+this.companyId);
        if (cmp.type != this.companyType) {
          this.$router.replace(this.backRoute);
          return;
        }
        this.company = cmp;
      },
      "Company not found"
    )
  }

  async created() {
    if (this.isNew) {
      this.company = new Company();
      this.company.type = this.$route.name == CompaniesRoutesEnum.CUSTOMER_DETAIL ? CompanyTypeEnum.CUSTOMER : CompanyTypeEnum.SUPPLIER;
    } else {
      await this._loadCompany();
    }
  }

}