import { Options, Vue } from "vue-class-component";
import { SessionStorageEnum } from "@/utils/SessionStorageEnum";
import { usersService } from "@services/users.service";

import {
  DynamicTable
} from "@components";

import { User } from "@/model/api/User";

import { FilterMatchMode } from "primevue/api";
import { Prop, Watch } from "vue-property-decorator";
import { CompanyTypeEnum } from "@/model/enums/CompanyTypeEnum";
import { CompaniesRoutesEnum } from "../router";
import { Company } from "@/model/api/Company";
import { companiesService } from "@services/companies.service";

@Options({
  name: "ListPage",

  components: {
    DynamicTable
  },

  beforeRouteEnter(to, from, next) {
    if (from.name && !(from.name as string).startsWith('SUPPLIERS')) {
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_SUPPLIERS);
    }
    if (from.name && !(from.name as string).startsWith('CUSTOMERS')) {
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_CUSTOMERS);
    }

    next();
  },
  beforeRouteLeave() {
    if (usersService?.cancelPendingRequests) {
      usersService.cancelPendingRequests();
    }
  },
})
export default class ListPage extends Vue {
  @Prop()
  readonly type!: CompanyTypeEnum;

  @Watch('type')
  private onTypeChange(val) {
    this.resetTable()
  }

  filters: any = null;
  tableKey = 0;

  get service() {
    return companiesService;
  }

  get stateKey() {
    return this.type == CompanyTypeEnum.CUSTOMER ? SessionStorageEnum.TABLE_STATE_CUSTOMERS : SessionStorageEnum.TABLE_STATE_SUPPLIERS
  }

  get newRoute() {
    return {
      name: this.type == CompanyTypeEnum.CUSTOMER ? CompaniesRoutesEnum.CUSTOMER_DETAIL : CompaniesRoutesEnum.SUPPLIER_DETAIL,
      params: { companyId: 'new' }
    }
  }

  get cols() {
    return [
      {
        field: 'name',
        header: "Name",
        placeHolder: "Name contains"
      },
      {
        field: 'email',
        header: "Email"
      },
      {
        field: 'phone',
        header: "Phone"
      },
      {
        field: 'business_name',
        header: "Business name"
      },
      {
        field: 'business_address',
        header: "Business address"
      },
      {
        field: 'business_city',
        header: "Business city"
      },
      {
        field: 'business_country',
        header: "Business country"
      },
    ] as {
      field?: keyof Company,
      [key: string]: any
    }[]
  }

  goToDetail(company: Company) {
    this.$router.push({
      name: this.type == CompanyTypeEnum.CUSTOMER ? CompaniesRoutesEnum.CUSTOMER_DETAIL : CompaniesRoutesEnum.SUPPLIER_DETAIL,
      params: { companyId: company.id }
    })
  }

  private initFilter() {
    this.filters = {
      global: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },

      type: {
        value: this.type,
        matchMode: FilterMatchMode.EQUALS
      },

      name: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH,
      },
      business_name: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH,
      },
      email: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      phone: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      business_address: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      business_city: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      business_country: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      }
    };
  }

  created() {
    this.initFilter();
  }

  get title() {
    return this.type == CompanyTypeEnum.CUSTOMER ? "Customers" : "Suppliers";
  }

  private resetTable() {
    this.initFilter();
    this.tableKey++;
  }
}
