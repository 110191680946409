export enum SessionStorageEnum {
    TABLE_STATE_USERS = "DT_USERS_STATE",
    TABLE_STATE_ADMINS = "DT_ADMINS_STATE",
    TABLE_STATE_ZONES = "DT_ZONES_STATE",
    TABLE_STATE_DUMPS = "DT_DUMPS_STATE",
    TABLE_STATE_CONTRACTS = "DT_CONTRACTS_STATE",
    TABLE_STATE_SUPPLIERS = "DT_SUPPLIERS_STATE",
    TABLE_STATE_CUSTOMERS = "DT_CUSTOMERS_STATE",

    TABLE_STATE_TICKETS = "DT_TICKETS_STATE",
    TICKETS_ADVANCED_FILTERS = "TICKETS_ADVANCED_FILTERS",

    TABLE_STATE_SHIFTS = "DT_SHIFTS_STATE",
    SHIFTS_ADVANCED_FILTERS = "SHIFTS_ADVANCED_FILTERS",

    TABLE_STATE_APP_SHIFTS = "DT_APP_SHIFTS_STATE",
    APP_SHIFTS_ADVANCED_FILTERS = "APP_SHIFTS_ADVANCED_FILTERS",

    TABLE_STATE_SHIFT_TICKETS = "DT_SHIFT_TICKETS_STATE",

    TABLE_STATE_TEXTS = "TABLE_STATE_TEXTS",
    TEXTS_ADVANCED_FILTERS = "TEXTS_ADVANCED_FILTERS",
}